import { useNavigate } from "react-router-dom";
import { MdOutlineClose } from 'react-icons/md';
import { motion } from "framer-motion";

export const BackButton = ({ iconbtn, color, bgcolor, path, type }) => {
    const navigate = useNavigate();

    const onLink = () => {
        return navigate(`/${path}`)  
    } 

    return (
        <motion.button
            className="backbutton"
            style={{ background: bgcolor ? bgcolor : "#FA4E37" }}
            onClick={onLink}
            whileHover={{ 
                scale: 1.1
            }}
            whileTap={{
                scale: 0.8,
            }}

            type={type}
        >
            <span style={{color: color,}}
            ><MdOutlineClose /></span>
        </motion.button>
    )
}