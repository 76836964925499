/* eslint-disable array-callback-return */
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ErrorPage from "../component/ui/errors/ErrorPage.js";
import HeadCustom from "../component/HeadCustom.js";
import { DataProject }  from '../data/Data.js';
import { BackButton } from "../component/ui/button/BackButton.jsx";
import { motion } from "framer-motion";

const DisplayProject = () => {
  const [ProjectDetail, setProjectDetail] = useState([]);
  const { slug } = useParams();

  useEffect(() => {
    setProjectDetail(projetSlug);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const projetSlug = DataProject.find(pro => pro.slug === slug);

  return (
    <>
      <HeadCustom headtitle={ProjectDetail.title} />
      {
        ProjectDetail ? (
          <motion.article 
            className="card_project_display" 
            key={ProjectDetail.id}

            initial={{ opacity: 0 }}
            transition={{ type:"spring", duration:.2, ease: "easeIn" }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <BackButton color="#fafafa" path="projets" />
            <div className="wrapper">
              <motion.h2 
                className="title_h1"
                initial={{ opacity: 0, translateY: 50 }}
                animate={{ opacity: 1, translateY: 0 }}
                transition={{ type:"spring", duration:.2, stiffness: 100 }}
                >{ ProjectDetail.title }</motion.h2>
              <p className="text">{ ProjectDetail.content }</p>
              <motion.div 
              
                className="box_pro"
                initial={{ opacity: 0, translateY: -50 }}
                animate={{ opacity: 1, translateY: 0 }}
                transition={{ type:"spring", duration:.2, stiffness: 100 }}
              >
                {
                  ProjectDetail.display && 

                  ProjectDetail.display[0] === "video" ?
                    <video width="700" height="400" className='project_video' playsInline autobuffer="true" controls preload="true"  poster={`/uploads/project/${ ProjectDetail.image }.webp`}>
                        <source src={`/uploads/project/${ ProjectDetail.display[1]}.mp4`} />
                        <source src={`/uploads/project/${ ProjectDetail.display[1]}.webm`} />
                    </video>  
                  :
                    <figure>
                        {
                           ProjectDetail.display && 
                          <img src={`/uploads/project/${ ProjectDetail.display[1]}.webp`} alt={ProjectDetail.title }/>
                        }
                    </figure>  
                }
                
              </motion.div>
            </div>
          </motion.article>
        ) : (
          < ErrorPage />
        )
      }
    </>   
  )
}

export default DisplayProject;  