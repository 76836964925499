import ErrorPage from "../component/ui/errors/ErrorPage";
import HeadCustom from "../component/HeadCustom";

const PageNotFound = () => {
  return (
    <>
      <HeadCustom headtitle={"Page Introuvable"} />
      <ErrorPage />
    </>
    
  )
}

export default PageNotFound;