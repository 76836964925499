import { AiOutlineInstagram } from "react-icons/ai";
import { FaTiktok } from "react-icons/fa";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer id="footer">
      <div className="wrapper">
        <ul className="list_social">
          {social.map((item) => (
            <li key={item.id} className="effect_hover">
              {" "}
              <a
                href={item.url}
                target="_blank"
                rel="noopener noreferrer"
                className="text"
              >
                <span>{item.icon}</span> {item.title}
              </a>
            </li>
          ))}
        </ul>
        <ul className="list_link_copyright">
          <li>
            <p className="text">
              Copyright ©2024. Mobalicorps. Tous droit reservés.
            </p>
          </li>
          <li>
            <Link to={"/mentionslegales"} className="effect_hover text">
              Mention légales
            </Link>
          </li>
        </ul>
      </div>
    </footer>
  );
};

export default Footer;

const social = [
  {
    id: 1,
    title: "Instagram",
    icon: <AiOutlineInstagram />,
    url: "https://www.instagram.com/mobali_corps/",
  },
  {
    id: 2,
    title: "TikTok",
    icon: <FaTiktok />,
    url: "https://www.tiktok.com/@mobalicorps",
  },
];
