export const Checkbox = ({ nm_check, inputOnchange, checked }) => {

    return (
        <div className="custom-checkbox">
            <input 
                type="checkbox" 
                id={ nm_check }
                name={ nm_check }  
                onChange={ () => inputOnchange(nm_check) }
                checked={ checked.includes(nm_check) } 
            />
            <label htmlFor={ nm_check }> { nm_check } </label>
        </div>
    )
}