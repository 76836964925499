import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { List } from "../../../features/user/component/List";
import { mydata } from "../../../features/user/userSlice";
import { Button } from "../button/Button";

export const Users = () => {
  const userLog = useSelector(mydata);

  if (userLog.role !== "admin") {
    localStorage.setItem("noAccess", "Accés Refusé!");
    return <Navigate to={"/mk-admin"} replace={true} />;
  }

  return (
    <article id="users">
      <div className="wrapper">
        <div className="box_top-title">
          <h2 className="title_h2">Les utilisateurs</h2>
          <Button
            namebtn="Ajouter"
            width
            color="#fafafa"
            bgcolor="#ddd"
            path="Creer"
          />
        </div>
        <List />
      </div>
    </article>
  );
};
