import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "../button/Button";
import { Checkbox } from "../forms/Checkbox";
import { Input } from "../forms/Input";

import { FiCamera, FiGrid, FiLoader } from "react-icons/fi";
import { useSelector } from "react-redux";
import { mydata } from "../../../features/user/userSlice.js";
import { DelButton } from "../button/DelButton";

import JoditEditor from "jodit-react";

export const Forms = ({ url, methode, edit_ }) => {
  const data = useSelector(mydata);

  const editor = useRef();
  const [message, setMessage] = useState();
  const [error, setError] = useState(false);

  const [formState, setFormState] = useState({
    title: "",
    content: "",
    picture: null || "",
    categories: "",
    status: false,
  });

  const { slug } = useParams();

  useEffect(() => {
    if (slug) {
      fetch(url)
        .then((res) => res.json())
        .then((data) => {
          setFormState({
            title: data.post.title,
            content: data.post.content,
            picture: data.post.picture || "", // Store the image name
            categories: data.post.categories,
            status: data.post.status,
          });
        })
        .catch((error) => {
          console.error("Error fetching post data:", error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug]);

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    const token = localStorage.getItem("token");

    // Verification des champs
    if (!formState.title || !formState.content) {
      setMessage(
        (msg) => (msg = "Veuillez remplir tous les champs obligatoires.")
      );
    }

    if (formState.picture === null || !formState.picture) {
      setMessage((msg) => (msg = "Veuillez choisir une image de fond."));
    }

    if (message) {
      return setError((err) => (err = true));
    }

    // Create the formData if the formState existe
    if (formState.picture) {
      const formData = new FormData();

      const originalname = formState.picture.name;
      formData.set("picture", formState.picture);

      formState.picture = originalname;

      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/posts/upload`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body: formData,
          }
        );

        if (!response.ok) {
          throw new Error("Échec de la requête.");
        }
      } catch (error) {
        console.error("Erreur lors du téléchargement de l'image:", error);
        setMessage(
          "Une erreur s'est produite lors du téléchargement de l'image. Veuillez réessayer."
        );
      }
    }

    // send body
    try {
      const res = await fetch(url, {
        method: methode,
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(formState),
      });

      if (!res.ok) {
        throw new Error("Request failed");
      } else {
        return navigate("/mk-admin/mes-articles");
      }
    } catch (error) {
      console.error("Erreur lors de la soumission du formulaire:", error.stack);
      setMessage(
        "Une erreur s'est produite lors de la soumission du formulaire. Veuillez réessayer."
      );
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormState((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleFileChange = (e) => {
    const { name } = e.target;

    if (name === "picture") {
      setFormState((prevState) => ({
        ...prevState,
        picture: e.target.files[0],
      }));
    } else {
      const { value } = e.target;
      setFormState((prevState) => ({ ...prevState, [name]: value }));
    }
  };

  const handleCheckboxChange = (category) => {
    if (formState.categories.includes(category)) {
      setFormState((prevState) => ({
        ...prevState,
        categories: prevState.categories.filter((item) => item !== category),
      }));
    } else {
      setFormState((prevState) => ({
        ...prevState,
        categories: [...prevState.categories, category],
      }));
    }
  };

  // CKEDITOR
  const handleEditorChange = (content) => {
    setFormState((prevState) => ({ ...prevState, content }));
  };

  return (
    <motion.form
      onSubmit={handleSubmit}
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 10 }}
      transition={{ duration: 0.8, type: "spring", ease: "easeIn" }}
    >
      <div className="left-box_form">
        {error && (
          <AnimatePresence>
            <span
              className="error-message"
              initial={{ opacity: 0, translateY: 25 }}
              animate={{ opacity: 1, translateY: 0 }}
              exit={{ opacity: 0, translateY: 25 }}
              transition={{ type: "spring", delay: 2.0, ease: "easeIn" }}
            >
              {message}
            </span>
          </AnimatePresence>
        )}
        <Input
          inputType="text"
          inputName="title"
          inputId="title"
          inputValue={formState.title || ""}
          inputPlaceholder="Ajouter un titre"
          inputOnchange={handleChange}
        />

        <JoditEditor
          ref={editor}
          value={formState.content}
          onChange={(newContent) => handleEditorChange(newContent)}
        />
      </div>

      <div className="right-box_form">
        <ul className="sidebar_admin-actus">
          <li>
            <p className="text">
              {" "}
              <span>
                <FiGrid />
              </span>{" "}
              Publication{" "}
            </p>
            <div className="body_publication">
              {data.role === "admin" && (
                <div className="input_status">
                  <span className="text">
                    <FiLoader /> Status
                  </span>
                  <div>
                    <input
                      type="checkbox"
                      id="status"
                      name="status"
                      checked={formState.status}
                      onChange={(e) =>
                        setFormState((prevState) => ({
                          ...prevState,
                          status: e.target.checked,
                        }))
                      }
                    />
                    <label htmlFor="status">Status</label>
                  </div>
                </div>
              )}
            </div>
            <div className="setting_publication">
              {edit_ && data.role === "admin" && <DelButton slug={slug} />}
              <Button
                namebtn="Publier"
                width={0}
                color="#fafafa"
                type="submit"
              />
            </div>
          </li>
          {/* <li>
            <p className="text">
              {" "}
              <span>
                <FiFeather />
              </span>{" "}
              Ajouter votre description
            </p>
            <Textarea
              name="descr"
              label="description"
              value={formState.descr || ""}
              onChange={handleChange}
              placeholder="Description*"
            />
          </li> */}
          <li>
            <p className="text">
              {" "}
              <span>
                <FiGrid />
              </span>{" "}
              Ajouter les categories
            </p>
            {options.map((item, index) => (
              <Checkbox
                nm_check={item}
                key={index + 1}
                inputOnchange={() => handleCheckboxChange(item)}
                checked={formState.categories}
              />
            ))}
          </li>
          <li>
            <p className="text">
              {" "}
              <span>
                <FiCamera />
              </span>{" "}
              Image de fond
            </p>
            <Input
              inputType="file"
              inputName="picture"
              inputId="picture"
              inputPlaceholder="Choisir une image"
              inputOnchange={handleFileChange}
            />

            {formState.picture && (
              <span>{formState.picture.name || formState.picture}</span>
            )}
          </li>
        </ul>
      </div>
    </motion.form>
  );
};

const options = ["LifeStyle", "Divertissement", "Partenaire"];
