import useFetch from "../../../hooks/useFetch"
import { FiEdit2, FiEdit3 } from 'react-icons/fi';

export const SideBox = () => {

    const { data } = useFetch(`${process.env.REACT_APP_API_URL}/api/posts`);

    const filteredPosts = data && data.post.filter( post => !post.status === true);

    const dataCard = [
        {
            id: 1,
            title: "Actualites",
            icon:FiEdit2,
            nb__: data && data.postLength
        },
        {
            id: 2,
            title: "Actus en attente",
            icon:FiEdit3,
            nb__: filteredPosts && filteredPosts.length
        }
    ]
    return (
        <div className="side-box_admin">
             <ul>
                {data &&
                    dataCard.map( card =>          
                    <li key={card.id}>
                        <div>
                            <span className="icon_actus-element">{<card.icon />}</span>
                        </div>
                        <div>
                            <span>{card.nb__}</span>
                            {card.title}
                        </div>
                    </li>
                    )
                }  
            </ul>
        </div>
       
    )
}