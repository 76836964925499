import { FiDownload } from "react-icons/fi";
import Logo_paris  from "../../../assets/images/logo_paris_black.png"
import { motion } from "framer-motion";

const WrapperSponsoring = () => {

    return (
        <article id="section-service_trois">
            <div className="wrapper">
                <div>
                    <h2 className="title_h1">Catalogue <span>Partenaire</span> </h2>
                    <p className="date-cat">2024</p>

                </div>
                <div>
                    <div className="box-img">
                        <figure>
                            <img src={ Logo_paris } alt="logo de la mobali production" />
                        </figure>
                    </div>
                </div>
            </div>

            <motion.a 
                className="fidownload" 
                title="Telecharger le catalogue"
                whileHover={{ 
                    scale: 1.2
                }}
                whileTap={{
                    scale: 0.8,
                    rotate: -90,
                    borderRadius: "100%"
                }}

                
                href={`./${pdf}`}
                target="_blank"
            >
                    <FiDownload />
            </motion.a>
        </article>
    )
}

export default WrapperSponsoring;

const pdf = "mobali_partenaire.pdf";