import { SideMenu } from "../ui/admin/SideMenu"

export const Sidebar = () => {
    return (
        <div className="sidebar">
            <div>
                <SideMenu />
            </div>
        </div>
    )
}