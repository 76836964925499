import { Outlet } from "react-router-dom"

export const LayoutActus = () => {
    return (
        <div id="actus-layout">
            <main>
                <Outlet />  
            </main>
        </div>
    )
}