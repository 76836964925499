export const Slider = ({ width }) => {
  return (
    <ul className="customer" style={{ width: width ? "70%" : "" }}>
      {logos.map((item, idx) => (
        <li className="slide" key={idx + 1}>
          <figure>
            <img src={`/uploads/${item}.webp`} alt={`lopgo de ${item}`} />
          </figure>
        </li>
      ))}
    </ul>
  );
};

const logos = [
  "cider",
  "bodyminute",
  "france",
  "moni",
  "boohoo",
  "Shein",
  "sixthjune",
  "sony",
  "prodlaitier",
  "aidemodial",
  "milkmakeup",
  "soldejaneiro",
  "nicework",
];
