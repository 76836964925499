import MyLogo from '../../../assets/images/logo_.png';
import MyLogo1 from '../../../assets/images/logo.png';

import { Link } from 'react-router-dom';

const Logo = ({color, size}) => {
   return (
      <Link to={"/"} style={{
         width:size
      }}>
         <div className="brand" style={{
            width:size
         }}>
            <figure style={{
            width:size
         }}>
               <img src={ color ? MyLogo : MyLogo1 } alt="Logo du site" />
            </figure>
         </div>
      </Link>     
   )
}

export default Logo;