import { motion } from "framer-motion";
import { FiArrowRight } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import Actus from "../../../features/actus/Actus";

const SectionWrapper = () => {
  const navigate = useNavigate();

  const handlerClick = () => {
    return navigate("/actus");
  };

  return (
    <article id="en_ce_momment">
      <div className="wrapper">
        <h2 className="title_h1" style={{ position: "relative" }}>
          Nos actualités !<span className="trait"></span>
        </h2>
        <Actus limit={3} image={true} home={true} status={true} />
        <motion.button
          type="button"
          onClick={handlerClick}
          whileHover={{
            scale: 1.1,
          }}
          whileTap={{
            scale: 0.9,
          }}
        >
          Voir plus{" "}
          <span>
            <FiArrowRight />
          </span>
        </motion.button>
      </div>
    </article>
  );
};

export default SectionWrapper;
