// Components
import { motion } from "framer-motion";
import Banner from "../component/ui/home/Banner";
// Images
import HeadCustom from "../component/HeadCustom";
import ComponentOne from "../component/ui/ComponentOne";
import ActusWrapper from "../component/ui/home/ActusWrapper";
import HomeWrapper from "../component/ui/home/HomeWrapper";

const Home = () => {
  return (
    <motion.div
      id="container"
      initial={{ opacity: 0 }}
      transition={{ type: "spring", duration: 0.1 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <HeadCustom headtitle={"Mobali Production"} />
      <ComponentOne id={1} bgcolor="#000" color="#fafafa" />
      <ActusWrapper />
      <HomeWrapper />
      <Banner />
    </motion.div>
  );
};

export default Home;
