import { motion } from "framer-motion";
import { useEffect, useState } from "react";

const Burger = ({ setshow, show }) => {
  const [anim, setAnim] = useState("open"); // Change l'état initial à "open"

  useEffect(() => {
    // Met à jour l'état anim après un délai de 200ms pour rendre l'animation fluide
    const timer = setTimeout(() => {
      setAnim(show ? "open" : "closed");
    }, 200);

    return () => clearTimeout(timer);
  }, [show]);

  const onBurgerClick = () => {
    setshow(!show);
  };

  const onClick = () => {
    setAnim("moving");
    onBurgerClick();

    setTimeout(() => {
      setAnim(anim === "closed" ? "open" : "closed");
    }, 200);
  };

  const path01Variants = {
    open: { d: "M3.06061 2.99999L21.0606 21" },
    closed: { d: "M0 9.5L24 9.5" },
  };

  const path02Variants = {
    open: { d: "M3.00006 21.0607L21 3.06064" },
    moving: { d: "M0 14.5L24 14.5" },
    closed: { d: "M0 14.5L15 14.5" },
  };

  return (
    <motion.div
      className={`burger-menu ${show ? "close" : ""}`}
      onClick={onClick}
    >
      <svg width="24" height="24" viewBox="0 0 24 24">
        <motion.path
          stroke="#FFFFFF"
          animate={anim}
          variants={path01Variants}
        />
        <motion.path
          stroke="#FFFFFF"
          animate={anim}
          variants={path02Variants}
        />
      </svg>
    </motion.div>
  );
};

export default Burger;
