import React from 'react';
import { createRoot } from "react-dom/client";
import { AuthProvider } from './context/AuthContext';

// Redux setting
import store from './app/store.js'
import { Provider } from 'react-redux'

import App from './App';

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  <Provider store={store} >
    <AuthProvider>
      <App />
    </AuthProvider> 
  </Provider>
    
);