import { Navigate, Outlet   } from "react-router-dom"
import { AdminMenu } from "../component/partials/AdminMenu"
import { Sidebar } from "../component/partials/Sidebar"
import { AuthContext } from "../context/AuthContext"
import { useContext } from "react"
// import { useSelector } from "react-redux"
// import { mydata } from "../features/user/userSlice"

export const LayoutAdmin = () => {
    const { isLoggedIn, isLoading } = useContext(AuthContext);
    // const data = useSelector(mydata);

    if (isLoading) {
        return <div>Loading ...</div>
    }

    if (!isLoggedIn) {
        return <Navigate to="/mk-login" replace={true} />
    }
    
    // if (data.role !== "admin") {
    //     localStorage.setItem("messageError", "Accès refusé")
    //     return <Navigate to="/accesrefuse" replace={true} />
    // }
    
    return (
        <div id="admin">
            <Sidebar />
            <div className="wrapper_admin">
                <AdminMenu />
                <Outlet />
            </div>   
        </div>
    )
}