import { useSelector } from "react-redux";
import { mydata } from "../../../features/user/userSlice";
import { Avatar } from '../Avatar';

export const Profil = () => {
    const data = useSelector(mydata);
    return(
        <div id="profil">
            <Avatar avatar={data.avatar} size={ size }/>
            <div className="info-author">
                <p className="text" style={{ marginBottom:"0px"}}>{data.firstname} {data.lastname}</p>
                <p className="text" style={{ fontSize:".745rem"}}>{data.email}</p>
            </div>
            
        </div>
    )
}

const size = {
    height: "50px",
    width: "50px"
}