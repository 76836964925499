import { motion } from 'framer-motion';
import { ListProject } from './component/ListProject';

const WrapperProject = () => {

  return (
   
    <motion.article id="projects">
      <div className="wrapper">
        <div 
          className="box-content"
        >
            <motion.h2 
              className="title_h1"
              initial={{ opacity: 0, translateY: 50 }}
              animate={{ opacity: 1, translateY: 0 }}
              transition={{ type:"spring", duration:.2, stiffness: 100 }}  
            >Nous avons déja travailler avec eux</motion.h2>
            <motion.p 
              className="text textcolab"
              initial={{ opacity: 0, translateY: 50 }}
              animate={{ opacity: 1, translateY: 0 }}
              transition={{ type:"spring", duration:.2, stiffness: 100 }}  
            >Nous collaborons avec des marques reconnues afin de concevoir, mettre en œuvre et réaliser des stratégies de marketing social et des campagnes d'influence novatrices. <span>Notre approche consiste à développer des stratégies personnalisées en matière de marketing social et d'influence, en prenant en compte les objectifs spécifiques de chaque marque.</span> </motion.p>
            <motion.p 
              className="mobaliparis"
              initial={{ opacity: 0, translateY: -50 }}
              animate={{ opacity: 1, translateY: 0 }}
              transition={{ type:"spring", duration:.2, stiffness: 100 }}
            ><span>Mobali</span>  <span>Projet</span></motion.p>
        </div>
        <hr />
        <ListProject />
      </div>
    </motion.article>

      
  )
}

export default WrapperProject;
