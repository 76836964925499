import { motion } from "framer-motion";
import HeadCustom from "../component/HeadCustom";
// Images
import WrapperProjects from "../features/project/WrapperProjects";

const Projet = () => {
    return (
        <motion.div 
        id="container"

        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        >
            <HeadCustom headtitle={"Mobali Projet"} />
            <WrapperProjects />
        </motion.div>
    )
}

export default Projet;