import { motion } from "framer-motion";
import Marketing from "../../../assets/images/bgbg.jpg";

const StratWrapper = () => {
  return (
    <article id="promo">
      <div className="wrapper">
        <div className="cont">
          <div className="box">
            <motion.h2
              className="title_h1"
              initial={{ translateX: -100 }}
              animate={{ translateX: 0 }}
              transition={{ type: "spring", duration: 0.2, stiffness: 100 }}
            >
              <span className="primary">Maîtrisez </span>l'Influence Marketing
              avec nous, votre partenaire de
              <span className="primary"> choix</span>
            </motion.h2>
            <motion.p
              className="text"
              initial={{ translateX: 100 }}
              animate={{ translateX: 0 }}
              transition={{
                type: "spring",
                duration: 0.2,
                delay: 0.1,
                stiffness: 100,
              }}
            >
              Les réseaux sociaux est le format dont les marques souhaitent
              étendre leur impact. Chacun des réseaux sociaux a sa
              particularité, Youtube, Instagram et Tiktok sont aujourd’hui les
              plateformes les plus favorables aux créations de contenu simple et
              rapide.
            </motion.p>
          </div>

          <div className="box">
            <motion.div
              className="img-box"
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ type: "spring", stiffness: 100, ease: "easeIn" }}
            >
              <figure>
                <img src={Marketing} alt="this is the description" />
              </figure>
            </motion.div>
          </div>
        </div>
      </div>
    </article>
  );
};

export default StratWrapper;
