
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { DataProject } from "../../../data/Data.js"

export const ItemProject = () => {
    const [project, setProject] = useState([]);

    useEffect(() => {
        setProject(DataProject);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <>
            {
                project.map( pro => 
                    <motion.li 
                        className="project-item" 
                        key={pro.id} 
                        style={{ overflow: "hidden"}}

                        initial={{ opacity: 0, translateY: -50 }}
                        whileInView={{ opacity: 1, translateY: 0 }}
                        transition={{ type:"spring", duration:.2, stiffness: 100 }}
                    >
                        <h3 
                            className="title_h3"

                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ delay: 0.2, duration: .2, ease:"easeOut" }}
                        >
                                { pro.title }
                        </h3>
                        <div className="box_img_project">
                            <figure>
                                <img src={ `./uploads/project/${pro.image}.webp`} alt={ pro.title} />
                            </figure>
                        </div>
                        <Link to={`/projets/${pro.slug}`} className="img_link"></Link>
                    </motion.li> 
                )
            }
        </>
    )
}