import { Helmet } from "react-helmet"

const HeadCustom = ({ headtitle, headdesc }) => {
    return (
        <Helmet>
            <title>{ headtitle }</title>
            <meta name="description" content={ headdesc ? headdesc : "La mobali production est votre partenaire" } />
        </Helmet>
    )
}

export default HeadCustom;