import { NavLink } from "react-router-dom"
import { Button } from "../button/Button";

const Navbar = ({ show }) => {
  let activeClassName = 'active'

  return (
    <nav className={`navbar ${ show ? 'show' : ''}`}>
      <ul className="list_nav">
        {
          navItems.map( item => 
            <li className="nav_item line_hover" key={item.id}>
              <NavLink 
                className={({ isActive }) => isActive ? activeClassName : "" } 
                to={ item.redirect_to } 
                title={ item.title }
              >
                { item.title }
              </NavLink>
            </li>
          )
        }

        { show && 
          <Button action namebtn="Contact" path="nouscontacter" color="#fafafa" width={0} />
        }
      
      </ul>

    </nav>
  )
}

export default Navbar;

const navItems = [
  {
    id: 1,
    title: "Accueil",
    redirect_to: "/"
  },
  {
    id: 2,
    title: "Services",
    redirect_to: "/services"
  },
  {
    id: 3,
    title: "Projets",
    redirect_to: "/projets"
  },
  {
    id: 4,
    title: "Actualités",
    redirect_to: "/actus"
  }
]