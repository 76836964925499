import Actus from "../../features/actus/Actus";
import { Button } from "../ui/button/Button";
import LogoInsta from "../../assets/images/instaimage.jpg"
import { Link } from "react-router-dom";

import { FiChevronLeft } from 'react-icons/fi';
import { FaTiktok, FaInstagram } from "react-icons/fa";
import { motion } from "framer-motion";

const WrapperBlog = () => {
    
  document.querySelector('body').style.overflow = '';
  return (
    <motion.article 
      id="actus"
      initial={{ opacity: 0 }}
      transition={{type:"spring", duration:.2 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div className="wrapper">
        <motion.div 
          className="actus-content"
          initial={{ opacity: 0, translateX:-400 }}
          transition={{type:"spring", duration:.45, ease:"easeIn" }}
          animate={{ opacity: 1, translateX:0}}
        >
          {/* header infos */}
          <div className="infos-header">
            <div className="left-infos">
              <h2 className="title_h3">{infos_agency.agency_name}</h2>
              <div className="link-infos">
                <Button 
                  namebtn="Contacter" 
                  color="#fafafa"
                  path="nouscontacter" 
                  action
                />
              </div>
            </div>
            
            <div className="right-infos">
              <div className="img-box">
                <figure>
                  <img src={ LogoInsta } alt="logo de la mobalicorps" />
                </figure>
              </div>
            </div>
          </div>

          {/* Infos agency */}
          <div className="infos-agency">
            <p className="text p1">{infos_agency.title}</p>
            <p className="text p2">{infos_agency.secteur}</p>
            <p className="text p1">{infos_agency.bio}</p>
            <ul className="list-agency">
              {
                infos_agency.list.map((item, idx) => 
                  <li key={idx+1} className="text">{item}</li>
                )
              }
            </ul>
            <hr />
          </div>

          <p className="text">Bienvenue sur la page d'actualités de la Mobalicorps, votre destination incontournable pour rester informé(e) des derniers événements, tendances de notre structure. </p>

        </motion.div>

        <Actus nbarticle={10} image={true} status={true}/>
        
        <div className="float_box">
          <p className="title_h3">Nous suivre</p>
            <ul className="list-agency">
              {
                infos_agency.listsocial.map((item, idx) => 
                  <li key={idx+1} className="text"> <span>{(idx+1) === 1 ? <FaInstagram /> : <FaTiktok />}</span> <a href={(idx+1) === 1 ? "https://www.instagram.com/mobalicorps/" : "https://www.tiktok.com/@mobalicorps"} target="_blank" without="true" rel="noreferrer">{item}</a>  </li>
                )
              }
            </ul>
        </div>

      </div>

      <span className="backtosite">
        <Link to={"/"} className="hover">{infos_agency.site}</Link>
        <span className="back_"><Link to={"/"}><FiChevronLeft /></Link></span>
      </span>

    </motion.article>
  )
}

export default WrapperBlog;

const infos_agency = {
  agency_name:"Mobalicorps",
  logo:"mobali.webp",
  title:"Agency Mobali",
  secteur:"Agence de marketing",
  bio:"Un monde remplis de Bull 🫧",
  site:"Revenir sur le site",
  list:["Publicité",  "Évent",  "Influence",  "Model"],
  listsocial:["Instagram", "Tiktok"]
}