import { motion } from "framer-motion";
import { currentDate } from '../../utils/myDate';
import { Logout } from "../ui/button/Logout"
export const AdminMenu = () => {
    return (
        <motion.div 
            className="admin-menu"
            initial={{ opacity:0,  }}
            animate={{ opacity: 1 }}
            transition={{ duration:.5, type:"spring", ease: "linear" }}
        >
            <div className="admin-date">
                <span className='text'>{ currentDate() }</span>
            </div>
            
            <div className="admin-info">
                <Logout />
            </div>
        </motion.div>
    )
}