import { useDispatch } from "react-redux";
import { clearUser } from '../../../features/user/userSlice';
import { FaSignOutAlt } from "react-icons/fa";
import { motion } from "framer-motion";

export const Logout = () => {
    const dispatch = useDispatch()

    const handleClickLogout = () => {
        localStorage.removeItem('token');
        dispatch(clearUser());
        window.location.replace('/mk-admin');
    }
    return (
        <motion.button 
            className="logout" 
            onClick={handleClickLogout}

            whileHover={{ 
                scale: 1.1
            }}
            whileTap={{
                scale: 0.9,
            }}
            title="se déconnecter"
        > <span><FaSignOutAlt /></span>
        </motion.button>
    )
}