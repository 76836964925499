import { Outlet } from "react-router-dom";

import { useSelector } from "react-redux";
import Footer from "../component/partials/Footer";
import Header from "../component/partials/Header";
import { DashButton } from "../component/ui/button/DashButton";
import { mydata } from "../features/user/userSlice";
const LayoutPage = () => {
  const data = useSelector(mydata);

  return (
    <>
      <Header />
      <DashButton isAdmin={data?.role} />
      <main>
        <Outlet />
      </main>
      <Footer />
    </>
  );
};

export default LayoutPage;
