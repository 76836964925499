import { motion } from "framer-motion";
import { FiFastForward, FiFeather, FiHash, FiTrendingUp } from "react-icons/fi";
import { Button } from "../button/Button";

const WrapperPresta = () => {
  return (
    <article id="mes-presta">
      <div className="wrapper">
        <div className="box-content">
          <motion.div
            className="content_preta"
            initial={{ translateY: 50 }}
            whileInView={{ translateY: 0 }}
            transition={{ type: "spring", duration: 0.45, stiffness: 100 }}
          >
            <h2 className="title_h2">
              Notre Pouvoir de Façonner la Perception
              <span></span>
            </h2>
            <p className="text" style={{ marginBottom: "45px" }}>
              nous façonnons votre perception digitale pour vous aider à
              atteindre vos objectifs avec succès. Faites confiance à notre
              savoir-faire pour donner à votre entreprise la visibilité et
              l'impact qu'elle mérite.
            </p>
            <Button
              action
              namebtn="Contacter"
              path="nouscontacter"
              color="#fafafa"
              width={0}
            />
          </motion.div>
        </div>

        <div className="box_preta">
          <ul className="list_preta">
            {listPresta.map((presta) => (
              <li key={presta.id}>
                <h3 className="title_h3">
                  {" "}
                  <span className="icon-presta"> {<presta.icon />} </span>{" "}
                  {presta.title}
                </h3>
                <p className="text">{presta.text}</p>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </article>
  );
};

export default WrapperPresta;

const listPresta = [
  {
    id: 1,
    title: "Promotion",
    icon: FiTrendingUp,
    text: "Nous mettons en avant des secteurs variés afin d'améliorer leur visibilité. Notre système, spécifiquement centré sur nos créateurs de contenu a pour objectif de mettre en lumière les enseignes et de susciter l'intérêt.",
  },
  {
    id: 2,
    title: "Divertissement",
    icon: FiHash,
    text: "La Mobali Production propose des concepts de jeux et de divertissement culturel, destinés à divertir sa communauté grâce à des diffusions sur divers réseaux sociaux tels que TikTok et Instagram.",
  },
  {
    id: 3,
    title: "Évènementiel",
    icon: FiFastForward,
    text: "Nous offrons des services spécialisés dans la conception et l'organisation d'une variété d'événements, notamment des événements festifs, des manifestations sportives et culturelles, ainsi que des événements professionnels.",
  },
  {
    id: 4,
    title: "Humanitaire",
    icon: FiFeather,
    text: "L'agence cherche à amplifier la lutte contre les discriminations (raciales, liées au handicap) en capitalisant sur l'influence de ces créateurs pour faciliter la diffusion des messages de sensibilisation.",
  },
];
