import { AiOutlineDelete } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { replaceDashesWithSpaces } from "../../../utils/replaceDashesWithSpaces";

export const DelButton = ({ slug, title }) => {
  const navigate = useNavigate();

  const token = localStorage.getItem("token");

  const onDelete = () => {
    const confirmDelete = window.confirm(
      `Êtes-vous sûr de vouloir supprimer l'article : ${replaceDashesWithSpaces(
        slug
      )} ?`
    );

    if (confirmDelete) {
      fetch(`${process.env.REACT_APP_API_URL}/api/posts/${slug}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          navigate(`/mk-admin/mes-articles`);
        })
        .catch((error) => {
          console.error("Erreur:", error);
        });
    }
  };

  return (
    <button className="btn-see_article font-btn" onClick={onDelete}>
      <span>
        <AiOutlineDelete />
      </span>
      Supprimer
    </button>
  );
};
