import { motion } from "framer-motion";
import { Card } from "./Card";

export const List = ({ limit, image, admin, home, status }) => {
  const item = {
    visible: { opacity: 1, x: 0 },
    hidden: { opacity: 0, x: -100 },
  };
  return (
    <motion.ul className={home ? "actus_list_home" : "actus_list"}>
      <Card
        limit={limit}
        image={image}
        admin={admin}
        m={item}
        status={status}
      />
    </motion.ul>
  );
};
