import { AnimatePresence, motion } from 'framer-motion';
import { AiOutlineDashboard } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';

export const DashButton = ( { isAdmin }) => {

    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/mk-admin')
    }
    return (

        <AnimatePresence>
            { isAdmin && 
                <motion.button
                    style={{
                        position: 'fixed',
                        bottom:'50px',
                        right:'50px',
                        borderRadius:'50%',
                        height:'45px',
                        width:'45px',
                        display:'flex',
                        alignItems:'center',
                        justifyContent:'center',
                        fontSize:'1.3rem',
                        border:'none',
                        cursor:'pointer',
                        color:'#fafafa',
                        zIndex:"10",
                        background:"#FA4E37"
                    }}

                    title='Tableau de border'
                    whileHover={{ 
                        scale: 1.1
                    }}
                    whileTap={{
                        scale: 0.9,
                    }}

                    onClick={ handleClick }
                >
                    <AiOutlineDashboard />
                </motion.button>
            }
        </AnimatePresence>
        
    )
}