import { FiZap } from "react-icons/fi";
import { ContactCustom } from "../component/ui/contact/ContactCustom";

export const Contact = () => {
  return (
    <ContactCustom
      name={infosContact.name}
      bgColor={infosContact.bgColor}
      descr={infosContact.descr}
      icon={infosContact.icon}
      packs={infosContact.packs}
      nbmodel={infosContact.nbmodel}
    />
  );
};

const infosContact = {
  name: "Contactez l'équipe d'Influence",
  descr: "test",
  bgColor: "#fafafa",
  icon: <FiZap />,
  packs: ["Model", "Promotion", "Immersion"],
  nbmodel: ["1-3", "4-7", "8-10"],
};
