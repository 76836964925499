import { Link } from "react-router-dom";

import { AnimatePresence, motion } from "framer-motion";
import { EditButton } from "../../../component/ui/button/EditButton.jsx";
import useFetch from "../../../hooks/useFetch.js";
import { customDate } from "../../../utils/myDate";
import { truncateTextByWords } from "../../../utils/truncateTextByWords.js";

export const Card = ({ limit, admin, m, status }) => {
  const apiUrl = `${process.env.REACT_APP_API_URL}/api/posts`;
  const { data, isLoading, error } = useFetch(apiUrl);

  const newArr = data?.post ? [...data.post] : [];

  const renderData = limit ? newArr.slice(0, limit) : newArr;

  return (
    <AnimatePresence>
      {error && <p>Oops! Aucun article trouvé.</p>}

      {!isLoading &&
        renderData.map((item) => (
          <motion.li
            className={`card_actus ${admin && "admincard"}`}
            key={item._id}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ type: "spring", stiffness: 200, damping: 50 }}
            exit={{ opacity: 0 }}
          >
            <div className="author-infos">
              <h3 className="title_h3">{item.title}</h3>
              <div className="avatar">
                <span className="text author">
                  @ {item.author_id.pseudo}{" "}
                  {admin === "admin" && <EditButton path={item.slug} />}
                </span>
              </div>
              {!admin && (
                <div
                  className="text descr"
                  dangerouslySetInnerHTML={{
                    __html: truncateTextByWords(item?.content, 15),
                  }}
                />
              )}
              {admin && (
                <p className="text descr">{customDate(item.createdAt)}</p>
              )}
            </div>

            <div className="box_actus_img">
              <Link
                to={`/actus/${item.slug}`}
                title={item.title}
                className="linkcard"
              >
                <motion.figure
                  whileHover={{
                    scale: 1.1,
                    transition: { type: "spring", stiffness: 200, damping: 50 },
                  }}
                  whileTap={{ scale: 0.9 }}
                >
                  <img
                    src={`${process.env.REACT_APP_API_URL}/uploads/${item?.picture}`}
                    alt={item.title}
                  />
                </motion.figure>
              </Link>
            </div>
          </motion.li>
        ))}
    </AnimatePresence>
  );
};
