import { motion } from "framer-motion";
import { useState } from "react";
import { Button } from "../ui/button/Button";
import Burger from "../ui/header/Burger";
import Logo from "../ui/header/Logo";
import Navbar from "../ui/header/Navbar";

const Header = () => {
  const [show, setShow] = useState(false);
  const [scroll, setScroll] = useState(false);

  // Change background header
  const changeBack = () => {
    window.scrollY >= 100 ? setScroll(true) : setScroll(false);
  };

  window.addEventListener("scroll", changeBack);

  // overflow the body
  if (show) {
    document.querySelector("body").style.overflow = "hidden";
  } else {
    document.querySelector("body").style.overflow = "";
  }

  return (
    <motion.header
      id="header"
      className={scroll ? "scrolling" : ""}
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition={{ duration: 0.2, type: "linear" }}
    >
      <div className="wrapper">
        <Logo color={true} size={35} />
        <Navbar show={show} setshow={setShow} />

        <Button
          action
          namebtn="Contact"
          path="nouscontacter"
          color="#fafafa"
          width={0}
          classbtn="btncontact"
        />

        <Burger setshow={setShow} show={show} />
      </div>
    </motion.header>
  );
};

export default Header;
