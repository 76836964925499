import useFetch from "../../../hooks/useFetch";
import { Card } from "./Card";

export const List = () => {
  const { data } = useFetch(`${process.env.REACT_APP_API_URL}/api/users`);

  return (
    <table className="tables">
      <thead>
        <tr>
          <th>Pseudo</th>
          <th>Nom</th>
          <th>Email</th>
          <th>Role</th>
        </tr>
      </thead>
      <tbody>
        <Card data={data} />
      </tbody>
    </table>
  );
};
