import {Routes, Route, useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
//Pages import routes
import Home from '../pages/Home';
import PageNotFound from '../pages/PageNotFound';
import Projects from '../pages/Projects';
import LayoutPage from '../layouts/LayoutPage';
import Actus from '../pages/Actus';
import DisplayActus from '../pages/DisplayActus';
import DisplayProject from '../pages/DisplayProject';
import { useEffect } from 'react';
import { LayoutAdmin } from '../layouts/LayoutAdmin';
import { Dashboard } from '../pages/Dashboard';
import { DashboardArticle } from './ui/admin/DashboardArticle';
import { Login } from '../pages/Login';
import { LayoutLogin } from '../layouts/LayoutLogin';
import { Create } from '../features/actus/pages/Create';
import { Users } from './ui/admin/Users';
import { Edit } from '../features/actus/pages/Edit';
import { Contact } from '../pages/Contact';
import Services from '../pages/Services';
import { LayoutActus } from '../features/actus/LayoutActus';
import { Mentions } from '../pages/Mentions';

const AnimatedRoutes = () => {
    const location = useLocation(); 

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
    }, [location.pathname]);
    
    return (
        <AnimatePresence mode="wait">
            <Routes location={location} key={location.pathname}>
                <Route element={<LayoutPage />}>
                    <Route path="/" element={<Home />} exact="true" />
                    <Route path="/services" element={<Services />} exact="true" />
                    <Route path="/projets" element={<Projects />} exact="true" />
                </Route> 

                <Route path="/mk-admin/*" element={<LayoutAdmin />}>
                    <Route index element={<Dashboard />} />
                    <Route path="mes-articles" element={<DashboardArticle />} />
                    <Route path="mes-articles/:slug" element={<Edit />} />
                    <Route path="creer" element={<Create />} />
                    <Route path="utilisateurs" element={<Users />} />
                </Route> 

                <Route element={<LayoutActus />}>
                    <Route path='/actus/:slug' element={<DisplayActus />} exact="true"></Route>
                    <Route path='/actus' element={<Actus />} exact="true"></Route>
                    <Route path="/projets/:slug" element={<DisplayProject />} exact="true" />
                    <Route path="/mentionslegales" element={<Mentions />} exact="true" />
                </Route>
                
                <Route element={<LayoutLogin />}>
                    <Route exact path="/mk-login" element={<Login />} />
                    <Route path='/nouscontacter' element={<Contact />} exact="true"></Route>
                    <Route path='/actus/:slug' element={<DisplayActus />} exact="true"></Route>
                </Route>

                <Route path="/*" element={<PageNotFound />} exact="true" />     
            </Routes>
        </AnimatePresence>
    )
}

export default AnimatedRoutes;