import StratWrapper from "../component/ui/strategy/StratWrapper";
import { motion } from "framer-motion";
import HeadCustom from "../component/HeadCustom";
import WrapperBanner from "../component/ui/about/WrapperBanner";
import WrapperPresta from "../component/ui/about/WrapperPresta";

const Services = () => {
    return (
        <motion.div 
            initial={{ opacity: 0 }}
            transition={{type:"spring", duration:.2 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            <HeadCustom headtitle={"Mobali Service"} />
            <StratWrapper />
            <WrapperPresta />
            <WrapperBanner />
        </motion.div>
    )
}

export default Services;