export const Card = ({ data }) => {
    
    return (
        <>
            {
                data && data.users.map( item => 
                    <tr>
                        <td> 
                            {item.pseudo}
                        </td>
                        <td>{item.firstname} {item.lastname}</td>
                        <td>{item.email}</td>
                        <td> 
                            <span 
                                className="role"
                                style={{ background: item.role === "admin" ? "#FDEFCE" : "#D4FDBE" }}
                            >
                                {item.role}
                            </span>
                        </td>
                    </tr>
                )
            }
        </>
    )
}