import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FiHome } from "react-icons/fi";
import { motion } from "framer-motion";


const ErrorPage = () => {
  const [message, setMessage] = useState(localStorage.getItem("messageError") || "");

  const navigate = useNavigate()
  const handleClick = () => {
    if (message) {
      localStorage.removeItem("messageError");
    }

    setMessage("")

    navigate("/");
  }

  return (
      <article id="landing_notfound">
        <div className="not_found">
          <h1 className="title_h3"> <span>404</span> { message ? message : "Page introuvable" }</h1>
          <motion.button 
          whileHover={{scale:1.1}} 
          onClick={ handleClick } 
          whileTap={{ scale:0.9}}
          transition={{ type:"spring", duration:.5, ease:"easeIn" }}
          className="link_notfound"
          
          > <span><FiHome /></span></motion.button>
        </div> 
      </article>
  )
}

export default ErrorPage;