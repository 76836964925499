import WrapperBlog from "../component/blog/WrapperBlog";
import HeadCustom from "../component/HeadCustom";

const Blog = () => {
    return (
      <>
        <HeadCustom headtitle={"Mobali Actus"} />
        <WrapperBlog />
      </>
      
    )
}

export default Blog;