import { TfiAngleDown } from "react-icons/tfi";
import { useMediaQuery } from "react-responsive";
import BannerVideoWebm from "../../../assets/video/banner_video.webm";
const Banner = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 868px)" });

  return (
    <div
      id="landing"
      style={{
        width: "100%",
        height: "100vh",
        left: "0",
        top: "0",
        backgroundImage: isMobile ? "url(./uploads/banner.webp)" : "none",
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      {!isMobile && (
        <div>
          <video
            className="banner_video"
            muted
            autoPlay
            loop
            playsInline
            style={{
              width: "100%",
              objectFit: "cover",
              left: "0",
              top: "0",
            }}
          >
            <source src={BannerVideoWebm} type="video/webm" />
          </video>
        </div>
      )}

      <a href="#component-one">
        <span className="icon-angle-down">
          <TfiAngleDown />
        </span>
      </a>
    </div>
  );
};

export default Banner;
