import Actus from "../features/actus/Actus";
import { useSelector  } from 'react-redux';
import { mydata } from '../features/user/userSlice';
import { Button } from "../component/ui/button/Button";
import { SideBox } from "../component/ui/admin/SideBox";
import { AnimatePresence } from "framer-motion";
import { useEffect, useState } from "react";
import { ErrorBox } from "../component/ui/errors/ErrorBox";

export const Dashboard = () => {
    const data = useSelector(mydata);

    const [message, setMessage] = useState(localStorage.getItem("noAccess") || "");

    useEffect(() => {
        const timer = setTimeout(() => {
            localStorage.removeItem("noAccess");
            setMessage("");
        }, 1000);

        return () => clearTimeout(timer);
    }, []);

    return(
        <article className="dashboard">
            <div className="leftbox-admin" style={{ position:"relative"}}>
                <div className="box_top-content">
                    <div>
                        <h2 className="title_h2">Salut {data.firstname}</h2>
                        <p className="text">
                        Salut et bienvenue dans l'espace d'administration ! Prêt(e) à partager du contenu pour la Mobalicorps ?
                        </p>
                        <Button 
                            namebtn="Ecrire un post" 
                            color="#fafafa" 
                            bgcolor="#4B4DFC" 
                            path="./mk-admin/creer" 
                            action 
                        />

                    </div>
                </div>

                {/* error message */}
                <AnimatePresence>
                    { message && 
                        <ErrorBox message={message} />
                    } 
                </AnimatePresence>

                <div className="recents-articles">
                    <p className="title_h2">Actualités récentes</p>
                    <Actus limit={3} admin={data.role} status={true} />
                </div>   
            </div>

            <SideBox />

        </article>
    )
}