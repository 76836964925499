export const DataProject = [
  {
    id: 1,
    title: "Boohoo",
    content:
      "Boohoo est une entreprise britannique de vente en ligne de vêtements. Elle a été fondée en 2006 par Mahmud Kamani. L’agence Mobali en collaboration avec Boohoo, été à la création de contenu TikTok et Instagram, en permettant le placement produit de l’avant première de la nouvelle collection de boohoo x Kourtney Kardashian.",
    display: ["photo", "boohoo_project"],
    image: "boohoo_project",
    slug: "boohoo",
  },
  {
    id: 2,
    title: "Sixth June",
    content:
      "Sixthjune est une marque streetwear avec laquelle l’agence Mobali a pu mener une campagne marketing en physique au sein de leur boutique bleue située à Châtelet. Des réalisations de création de contenu via nos micros influenceurs ont été réalisées avec les tenues de Sixthjune. Par ailleurs, notre caméraman a pu créer une mini séquence promotionnelle  permettant la prise de vue de la collaboration.",
    display: ["video", "sixthjune"],
    image: "sixthjune_project",
    slug: "sixth-june",
  },
  {
    id: 3,
    title: "Cider",
    content:
      "Cider est une marque de mode internationale mettant un point sur les valeurs sociales. Notre agence à contribué à une collaboration permettant la création de contenu de marque promotionnel à travers les réseaux sociaux, tels que Instagram et TikTok.",
    display: ["photo", "cider_project"],
    image: "cider_project",
    slug: "cider",
  },
  {
    id: 4,
    title: "Ruby",
    content:
      "Le Ruby anciennement connu sous le nom du Cosi lounge est un restaurant lounge à ambiance festive, dans lequel notre agence s’est associée sur plusieurs campagnes promotionnelles dans le but de donner différents aspect visuel à travers nos modèles et nos créatrice de contenu de l’agence. Nous avons permis au Ruby de réaliser une vidéo courte et captivante avec nos modèles de l’agence , directement poster sur leur page Instagram. De plus nous travaillons avec le Ruby sur d'autres projets événementiels tel que l'organisation de soirée festive à thème et des projets de création de contenu pour attirer une nouvelle source de clientèle.",
    display: ["video", "rubis_project"],
    image: "rubis_project",
    slug: "ruby",
  },
  {
    id: 5,
    title: "Flink",
    content:
      "Flink est un service de livraison à la demande qui livre des articles du quotidien directement aux consommateurs en seulement 10minutes, depuis les soi-disant 'dark stores' Cristal créatrice de contenue « chroniqueuse » à réaliser cette campagne promotionnelle du service Flink sur Instagram.",
    display: ["photo", "flink_project"],
    image: "flink_project",
    slug: "flink",
  },
  {
    id: 6,
    title: "Aide Mondiale",
    content:
      "L'agence Mobali est fière de vous annoncer son partenariat avec aide Mondiale, étant une association humanitaire luttant contre la précarité infantile à travers le monde depuis 2001. Ils étendent leurs actions au Benin, Philippines, Madagascar et la France. Des événements, tout au long de l'année sont organisés pour récolter des fonds afin de permettre des stages humanitaires chaque année et apporter de l'aide financier et morale aux enfants défavorisés. En France, l'Aide Mondiale organise des maraudes et apporte de l'aide aux migrants à Calais. Grâce à Aide Mondiale, plus de 1.000 enfants sont parrainés chaque année, et ce, depuis 2001.",
    display: ["photo", "aidemondiale_project"],
    image: "aidemondiale_project",
    slug: "aide-mondiale",
  },
  {
    id: 7,
    title: "Don-K Comedy club",
    content:
      "Le Don-K Comedy club est un espace situé en plein cœur de Saint-Germain-des-Prés à Paris spécialisé dans l’humour. Le Don-K Comedy Club ouvre ses portes du mardi au dimanche de 19h30 à 1h. L’opportunité pour vous de retrouver, chaque soir, les meilleurs humoristes du moment et de découvrir les futures stars de l’humour.  L’agence Mobali à participer à l’immersion d’un show toute en développant par l’un de ses concept «  Mobali View », le côté marketing de l’enseigne.",
    display: ["photo", "donk_project"],
    image: "donk_project",
    slug: "don-k-comedy-club",
  },
  // {
  //     "id": 8,
  //     "title": "Eye Five",
  //     "content": "Eye Five est un opticien qui a collaboré avec Mobali pour une session de tournage et de photographie au sein de sa boutique. Ctette collaboration visait à promouvoir et à valoriser l'enseigne, mettant en lumière les produits et services offerts par Eye Five.",
  //     "display": ["photo", "kvlvshnikov_project"],
  //     "image": "kvlvshnikov_project",
  //     "slug": "eyefive"
  // },
  // {
  //     "id": 9,
  //     "title": "temu",
  //     "content": "Le Don-K Comedy club est un espace situé en plein cœur de Saint-Germain-des-Prés à Paris spécialisé dans l’humour. Le Don-K Comedy Club ouvre ses portes du mardi au dimanche de 19h30 à 1h. L’opportunité pour vous de retrouver, chaque soir, les meilleurs humoristes du moment et de découvrir les futures stars de l’humour.  L’agence Mobali à participer à l’immersion d’un show toute en développant par l’un de ses concept «  Mobali View », le côté marketing de l’enseigne.",
  //     "display": ["photo", "temu_project"],
  //     "image": "temu_project",
  //     "slug": "temu"
  // },
].reverse();
