import { AnimatePresence, motion } from "framer-motion";
import useFetch from "../../../hooks/useFetch.js";
import { CustomTable } from "../CustomTable.jsx";
import { Button } from "../button/Button.jsx";
import { FiDatabase } from "react-icons/fi";
import { Navigate } from "react-router-dom";
import { mydata } from "../../../features/user/userSlice.js";
import { useSelector } from "react-redux";


export const DashboardArticle = () => {
    const { data, isLoading, error } = useFetch(`${process.env.REACT_APP_API_URL}/api/posts`);

    const userLog = useSelector(mydata);

    if (userLog.role !== "admin") {
        localStorage.setItem("noAccess", "Accés Refusé!" )
        return <Navigate to={"/mk-admin"} replace={true} />
    }
    return(
        <AnimatePresence>
            { !isLoading && 
            <motion.article 
                id="all_articles-admin"
                initial={{ opacity:0}}
                animate={{ opacity:1}}
                exit={{ opacity:0}}
                transition={{ type:"spring", duration:.525, ease:"easeIn"}}    
            >
                <div className="wrapper">
                    <div className="content-top">
                        <h2 className="title_h2">Article</h2>
                        <Button namebtn="Ecrire" color="Créer" bgcolor="Créer" action path="./mk-admin/creer" classbtn="btn-admin_button" type="button"/>
                    </div>
                    { error ?
                        <div
                        style={{ minHeight:"500px", display:"flex", alignItems:"center", justifyContent:"center", gap:"5px"}}
                        >
                            <p 
                                className="text" 
                                style={{ display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center", gap:"5px"}}
                            > 
                                <span style={{ fontWeight:800, color:"#ddd", fontSize:"2.0rem"}}><FiDatabase /></span> 
                                Oops, l'affichage des données a échoué.
                            </p>
                        </div>
                        
                    :

                    <CustomTable data={data} title_th={title_th} />
                    }
                    
                </div>
            </motion.article>
            }
        </AnimatePresence>
        
    )
}

const title_th = [ "Titre", "Auteur", "Categories", "Status", "Date"]