import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { Forms } from "../../../component/ui/admin/Forms";
import { mydata } from "../../user/userSlice";

export const Create = () => {
  const userLog = useSelector(mydata);
  const apiUrl = `${process.env.REACT_APP_API_URL}/api/posts`;

  if (userLog.role !== "admin") {
    localStorage.setItem("noAccess", "Accés Refusé!");
    return <Navigate to={"/mk-admin"} replace={true} />;
  }

  return (
    <article id="create-post">
      <div className="wrapper">
        <Forms url={apiUrl} methode="POST" />
      </div>
    </article>
  );
};
