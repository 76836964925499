import { motion } from "framer-motion";
import { FaSignInAlt } from "react-icons/fa";
import { useNavigate } from "react-router-dom"
import { FiArrowRight } from "react-icons/fi";

export const Button = ( { namebtn, width, color, bgcolor, path, classbtn, action, type, iconlog, icon } ) => {
    const navigate = useNavigate();

    const onLink = () => {
        return navigate(`/${path}`)  
    } 
 
    return (
        <motion.button 
            className={`btn font-btn ${classbtn} border-radius`} 
            onClick={ action &&  onLink } 
            style={{
                color: color,
                width: width === 1 ? "100%" : "auto",
                background: bgcolor ? bgcolor : "#FA4E37"
            }}

            whileHover={{ 
                scale: 1.1
            }}
            whileTap={{
                scale: 0.9,
            }}

            type={type}
        > 
            {iconlog && <span><FaSignInAlt /></span>} { namebtn }
            {icon && <span><FiArrowRight /></span>}
        </motion.button>
    )
}