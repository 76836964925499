import { AiOutlineEdit } from "react-icons/ai"
import { useNavigate } from "react-router-dom"

export const EditButton = ({ path }) => {
    const navigate = useNavigate();
    const Edit = () => {
        return navigate(`/mk-admin/mes-articles/${path}`)
    }
    return (
        <button className="btn-see_article font-btn" onClick={Edit} title={path}>
            <span><AiOutlineEdit /></span>
        </button>
    )
}