import { motion } from "framer-motion"
import { useEffect } from "react";
import { AiOutlineWarning } from 'react-icons/ai';
import { FiX } from "react-icons/fi";

export const AlertMessage= ({ setMessage, typeAlert, message }) => {

    const handleCloseAlert = () => {
        setMessage("");
    }

    useEffect(() => {
        if (message) {
            window.scrollTo({ top: 0, behavior: "smooth" });
        }
    }, [message])

    return (
            <motion.div
                className={`${ typeAlert === "warning" ? 'alert-warning' : 'alert-success'} alert`}
                initial={{ opacity: 0, translateY:-25 }}
                animate={{ opacity:1, translateY: 0 }}
                exit={{ opacity: 0, translateY:-25 }}
                transition={{type:"spring", duration: 0.5, ease:"linear" }}
            >
                <span><AiOutlineWarning /> { message }</span>
                
                <span><motion.button whileHover={{ scale: 1.1, transition: { duration:.12}}} type="button" onClick={handleCloseAlert}><FiX /></motion.button> </span>
            </motion.div>
        
    )
}