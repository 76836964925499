export const Input = (props) => {
    const { inputType, inputName, inputId, inputValue, inputPlaceholder, inputOnchange, checked, item } = props;
    return (
        <div className="form-group">
            <label htmlFor={!item ? inputName : item } ></label>
            <input 
                type={inputType} 
                id={inputId}
                name={inputName}
                value={inputValue}
                onChange={inputOnchange}
                placeholder={inputPlaceholder}
                className="form-control"
                checked={checked}
            />
        </div>
    )
}