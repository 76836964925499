import emailjs from "@emailjs/browser";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AlertMessage } from "../alert/AlertMessage";
import { BackButton } from "../button/BackButton";
import { Button } from "../button/Button";
import { Input } from "../forms/Input";

export const ContactCustom = ({ name, bgColor }) => {
  const form = useRef();
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedBudget, setSelectedBudget] = useState(null);

  const [isOrga, setIsOrga] = useState(false);

  const [formState, setFormState] = useState({
    nameoforga: "",
    name: "",
    prenom: "",
    email: "",
    description: "",
    telephone: "",
    who: null,
    budget: null,
  });

  const [message, setMessage] = useState("");

  useEffect(() => {
    if (!selectedOption?.includes("particulier") && selectedOption) {
      setIsOrga(true);
    } else {
      setIsOrga(false);
    }
  }, [selectedOption]);

  const navigate = useNavigate();

  const templateParams = {
    nom: formState.name,
    prenom: formState.prenom,
    phone: formState.telephone,
    email: formState.email,

    nameoforga: formState.nameoforga,

    who: formState.who,
    budget: formState.budget,

    description: formState.description,
  };

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .send(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        templateParams,
        process.env.REACT_APP_PUBLIC_KEY
      )
      .then(
        (result) => {
          window.alert(
            "Votre message a été envoyé avec succès ! Nous revenons à vous dans les heures qui suivent."
          );
          // Réinitialiser les champs du formulaire et le messsage d'erreur
          setFormState({
            nameoforga: "",
            name: "",
            prenom: "",
            email: "",

            description: "",
            telephone: "",

            who: null,
            budget: null,
          });

          setMessage("");
          navigate("/");
          console.log(result.text);
        },
        (error) => {
          window.alert(
            "Votre message a été transmis avec succès ! Nous prendrons contact avec vous dans les heures à venir."
          );
          navigate("/");
          console.log(error.text);
        }
      );
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Verification des champs
    if (
      !formState.name ||
      !formState.prenom ||
      !formState.email ||
      !formState.description ||
      !formState.telephone ||
      formState.who === null ||
      formState.budget === null
    ) {
      return setMessage({
        msg: "Veuillez remplir tous les champs",
        type: "warning",
      });
    }
    if (!/\S+@\S+\.\S+/.test(formState.email)) {
      return setMessage({ msg: "L'email n'est pas valide", type: "warning" });
    }

    sendEmail(e);
  };

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setFormState((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleChange = (e) => {
    const { name, value, type } = e.target;
    const newData = data.map((item) => item.nameInput);
    const newDataBudget = dataBudget.map((item) => item.nameInput);

    if (type === "radio" && newData.includes(name)) {
      setSelectedOption((item) => (item = e.target.value));

      setFormState((prevData) => ({
        ...prevData,
        who: e.target.value,
      }));
    } else if (type === "radio" && newDataBudget.includes(name)) {
      setSelectedBudget((item) => (item = e.target.value));

      setFormState((prevData) => ({
        ...prevData,
        budget: e.target.value,
      }));
    } else {
      setFormState((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  return (
    <motion.article
      id="orderCustom"
      style={{ background: bgColor }}
      initial={{ opacity: 0 }}
      transition={{ type: "spring", duration: 0.2 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <BackButton color="#fafafa" path="" bgcolor="#000" />
      <div className="wrapper">
        <motion.div
          className="box-content"
          initial={{ opacity: 0, translateY: -50 }}
          transition={{ type: "spring", duration: 0.2, stiffness: 100 }}
          animate={{ opacity: 1, translateY: 0 }}
        >
          <h2 className="title_h1">
            {name}
            <span className="trait"></span>
          </h2>
          <p className="text">
            Que vous ayez des questions, des demandes de collaboration ou
            simplement envie de discuter, n'hésitez pas à nous contacter. Notre
            équipe est là pour vous aider!
          </p>
        </motion.div>
        <AnimatePresence>
          {message && (
            <AlertMessage
              setMessage={setMessage}
              typeAlert={message.type}
              message={message.msg}
            />
          )}
        </AnimatePresence>
        <motion.form
          ref={form}
          onSubmit={handleSubmit}
          className="forms"
          initial={{ opacity: 0, translateY: 100 }}
          transition={{ type: "spring", duration: 0.2, stiffness: 100 }}
          animate={{ opacity: 1, translateY: 0 }}
        >
          <div className="boxForm">
            <h3 className="title_h3">Vos coordonnées</h3>

            <div className="form-flex">
              <Input
                inputType="text"
                inputName="name"
                inputId="name"
                inputValue={formState.name}
                inputPlaceholder="Nom"
                inputOnchange={handleChangeInput}
              />
              <Input
                inputType="text"
                inputName="prenom"
                inputId="prenom"
                inputValue={formState.prenom}
                inputPlaceholder="Prenom"
                inputOnchange={handleChangeInput}
              />
            </div>

            <div className="form-flex">
              <Input
                inputType="phone"
                inputName="telephone"
                inputId="telephone"
                inputValue={formState.telephone}
                inputPlaceholder="Numéro de téléphone"
                inputOnchange={handleChangeInput}
              />
              <Input
                inputType="email"
                inputName="email"
                inputId="email"
                inputValue={formState.email}
                inputPlaceholder="Email professionnel"
                inputOnchange={handleChangeInput}
              />
            </div>
          </div>

          {/* Qui vous êtes */}
          <div className="boxForm">
            <h3 className="title_h3">Qui vous êtes ?</h3>

            <div className="radiobox">
              {data.map((item) => (
                <div key={item.id}>
                  <label htmlFor={item.nameInput} className="text">
                    {item.title}
                  </label>
                  <input
                    type="radio"
                    name={item.nameInput}
                    id={item.nameInput}
                    className="form-control"
                    value={item.title}
                    checked={selectedOption === item.title}
                    onChange={handleChange}
                  />
                </div>
              ))}
            </div>
          </div>

          {/* Si pas particulier demander organisme */}
          <AnimatePresence>
            {isOrga && (
              <motion.div
                style={{ width: "100%" }}
                initial={{ opacity: 0, translateY: -25 }}
                animate={{ opacity: 1, translateY: 0 }}
                exit={{ opacity: 0, translateY: -25 }}
                transition={{ type: "spring", duration: 0.5, ease: "easeIn" }}
              >
                <Input
                  inputType="text"
                  inputName="nameoforga"
                  inputId="nameoforga"
                  inputValue={formState.nameoforga}
                  inputPlaceholder="Nom de l'organisme"
                  inputOnchange={handleChangeInput}
                />
              </motion.div>
            )}
          </AnimatePresence>

          {/* Quel es votre budget */}
          {/* Qui vous êtes */}
          <div className="boxForm">
            <h3 className="title_h3">Votre budget</h3>

            <div className="radiobox">
              {dataBudget.map((item) => (
                <div key={item.id}>
                  <label htmlFor={item.nameInput} className="text">
                    {item.title}
                  </label>
                  <input
                    type="radio"
                    name={item.nameInput}
                    id={item.nameInput}
                    className="form-control"
                    value={item.title}
                    checked={selectedBudget === item.title}
                    onChange={handleChange}
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="form-group-area">
            <textarea
              name="description"
              id="description"
              placeholder="Décrivez-nous votre projet"
              onChange={handleChangeInput}
            ></textarea>
          </div>

          <Button namebtn="Envoyer" color="#fafafa" type="submit" />
        </motion.form>
      </div>
    </motion.article>
  );
};

const data = [
  {
    id: 1,
    nameInput: "privateenterprise",
    title: "Une entreprise privée",
  },
  {
    id: 2,
    nameInput: "publicService",
    title: "Service public",
  },
  {
    id: 3,
    nameInput: "cooperative",
    title: "Une coopérative",
  },
  {
    id: 4,
    nameInput: "particulier",
    title: "Un particulier",
  },
];

const dataBudget = [
  {
    id: 1,
    nameInput: "promotion",
    title: "Moins de 5K euros ",
  },
  {
    id: 2,
    nameInput: "budget1",
    title: "Entre 5K et 10K euros ",
  },
  {
    id: 3,
    nameInput: "budget2",
    title: "Entre 10K et 30K euros ",
  },
  {
    id: 4,
    nameInput: "budget3",
    title: "Entre 30K et 100K euros ",
  },
  {
    id: 5,
    nameInput: "budget4",
    title: "Plus de 100k",
  },
];
