import { createContext } from 'react';
import useAuth from '../hooks/useAuth';
export const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
    const { isLoggedIn, isLoading, error } = useAuth();

    return (
        <AuthContext.Provider value={{ isLoggedIn, isLoading, error }}>
            { children }
        </AuthContext.Provider>
    )
}

export default AuthProvider;