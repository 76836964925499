import { AnimatePresence, motion } from "framer-motion";
import { Navigate, useParams } from "react-router-dom";
import { BackButton } from "../../../component/ui/button/BackButton.jsx";
import useFetchBySlug from "../../../hooks/useFetchBySlug.js";

const CardBySlug = () => {
  const apiUrl = `${process.env.REACT_APP_API_URL}/api/posts/`;
  const { slug } = useParams();

  const { data, isLoading, error } = useFetchBySlug(apiUrl + slug);

  if (error) {
    return <Navigate to="/pageintrouvable" replace="true" />;
  }

  return (
    <AnimatePresence>
      {!isLoading && (
        <motion.article
          className="card_actus_display"
          initial={{ opacity: 0 }}
          transition={{ type: "spring", stiffness: 100, damping: 50 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <BackButton color="#fafafa" path="actus" />
          <div className="wrapper">
            <motion.div
              className="content_actus"
              initial={{ opacity: 0, translateY: 50 }}
              animate={{ opacity: 1, translateY: 0 }}
              transition={{ type: "spring", duration: 0.2, stiffness: 100 }}
            >
              <h2 className="title_h2">{data?.title}</h2>
              {/* <p className="text">{customDate(data.createdAt)}</p> */}
            </motion.div>
            <motion.div
              className="box_actus_img"
              initial={{ opacity: 0, translateY: 50 }}
              animate={{ opacity: 1, translateY: 0 }}
              transition={{
                type: "spring",
                delay: 0.1,
                duration: 0.2,
                stiffness: 100,
              }}
            >
              <figure>
                <img
                  src={`${process.env.REACT_APP_API_URL}/uploads/${data?.picture}`}
                  alt={data?.title}
                />
              </figure>
            </motion.div>
            <div
              className="boxcontent"
              dangerouslySetInnerHTML={{ __html: data?.content }}
            />
          </div>
        </motion.article>
      )}
    </AnimatePresence>
  );
};

export default CardBySlug;
