export const truncateTextByWords = (text, maxWords) => {
  const words = text?.split(" ");

  if (words?.length <= maxWords) {
    // Si le texte contient moins de mots que la limite, retournez-le tel quel.
    return text;
  } else {
    // Sinon, tronquez le texte au nombre maximal de mots et ajoutez "..." à la fin.
    const truncatedText = words?.slice(0, maxWords).join(" ");

    return truncatedText + "...";
  }
};
